import { useCorpFetcher, type Pagination } from '~/shared/lib'

import type { Profile } from '~/entities/profile'

// eslint-disable-next-line boundaries/element-types
import { getAttachments } from '~/features/attachments'

import { type ListUserProfilesPageRequest } from '../lib'

export const listUserProfilesPage = async ({
  page = {
    number: 1,
    size: 1
  },
  filter = { query: '', ids: [], return_blocked: false }
}: ListUserProfilesPageRequest): Promise<{
  userProfiles: Profile[]
  totalPages: number
}> => {
  const {
    data: { userProfiles, pagination }
  } = await useCorpFetcher<{ userProfiles: Profile[]; pagination: Pagination }>({
    endpoint: `/passport.idm.Idm/ListUserProfilesPage`,
    method: 'POST',
    body: {
      page,
      filter
    }
  })
  const attachmentsIds = userProfiles
    .map((user) => user.attachments)
    .flat()
    .filter((attachment) => Boolean(attachment)) as string[]
  const attachments = await getAttachments({
    ids: attachmentsIds,
    variant: {
      resize: {
        method: 'RESIZE_METHOD_FILL',
        width: 200,
        height: 200
      }
    },
    disposition: 'DISPOSITION_INLINE'
  })
  userProfiles.forEach((user) => {
    const fullUser = userProfiles.find((item) => item.id === user.id)
    if (fullUser) {
      fullUser.avatar = attachments.find((attachment) => attachment.attachableId === user.id)?.variantUrl
    }
  })
  return { userProfiles, totalPages: pagination.totalPages }
}
